"use client";
import { Modal } from "@/app/[locale]/components/shared/Modal";
import { useRouter } from "@/navigation";
import LoginForm from "../../login/LoginForm";

export default function LoginModal({ text }) {
  const router = useRouter();

  return (
    <Modal handleClose={router.back} withCloseButton>
      <div className="card mx-auto my-4 w-full bg-neutral p-4">
        <LoginForm text={text} />
      </div>
    </Modal>
  );
}
